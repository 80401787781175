import PropTypes from "prop-types";
import React from "react";

import { MESSAGES } from "@/constants";

import styles from "./YouTubeEmbed.module.scss";

const YouTubeEmbed = ({ title = MESSAGES.TAGLINE, url }) => {
  const getYouTubeId = (url) => {
    const splittedURL = url.split("/");

    if (splittedURL.length > 0) {
      return splittedURL[splittedURL.length - 1];
    }

    return null;
  };

  return (
    <iframe
      allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope;"
      allowfullscreen
      className={styles.responsiveVideo}
      src={`https://www.youtube.com/embed/${getYouTubeId(url)}?rel=0&autoplay=0&loop=1&playlist=${getYouTubeId(url)}`}
      title={title}
    ></iframe>
  );
};

YouTubeEmbed.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};

export default YouTubeEmbed;
